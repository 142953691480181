.heroBlog {
  display: flex;
  background-color: var(--secondary-color);
  min-height: 400px;
  width: 100%;
  position: relative;
}

.introduction{
  margin: 20px 0 0 1rem;
  max-width: 650px;
  font-size: 0.9rem;
  line-height: 1.5;
}

.articleMeta{
  display: flex;
  margin: 70px 0 0 1rem;
  font-size: 0.8rem;
  align-items: center;
  flex-wrap: wrap;
}

.articleMeta p{
  padding: 0.4rem;
  margin: 0 0.5rem 0.5rem 0;
  border: solid 1px var(--primary-color);
}

.topicTag{
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.articleWrapper {
  min-height: 40px;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}

.sectionsOverview{
  background-color: transparent;
  border-left: solid 1px var(--secondary-color);
  color: var(--secondary-color);
  width: calc(100% - 60px);
  position: sticky;
  top: 90px; /* Add this to define where it should stick */
  z-index: 1000;
  padding: 30px;
  margin: 90px 0 40px 0;
  height: auto; /* Ensure height is only based on content */
  align-self: start; 
}

.scrollMarker {
  position: absolute;
  left: -3px;
  width: 5px;
  height: 20px;
  background-color: var(--secondary-color);
  transition: top 0.1s ease;  /* Smooth transition */
}

.sectionsOverview h2{
  font-weight: 600;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 40px;
}

.sectionsOverview ul{
  padding-inline-start: 5px;
}

.sectionsOverview p{
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.5;
}

.sectionsOverview p:hover{
  opacity: 0.6;
}

.articleInner {
  width: 100%;
  padding: 2rem 0;
  margin-inline: auto;
  grid-column: span 2;
}

.heroInner {
  width: 100%;
  max-width: 1200px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1rem;
}

.heroBlog h1, .section h1 {
  font-weight: 600;
  font-size: 3rem;
  margin-top: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

.section h1 {
  color: var(--text-color);
  margin: 40px 0 40px 1rem;
}

.articleInner h2 {
  font-weight: 600;
  font-size: 2rem;
  max-width: 650px;
  color: var(--secondary-color);
  margin-left: 1rem;
  margin-top: 4rem;
}

.backLink{
  margin: 140px 0 0 1rem;
  font-size: 1rem;
  color: var(--primary-color);
}

.articleInner h4 {
  font-weight: 600;
  color: var(--text-color);
  font-size: 1.6rem;
  margin: 50px 0 0 1rem;
}

.articleInner p, .articleInner li {
  font-size: 0.9rem;
  max-width: 650px;
  line-height: 1.5;
  color: var(--text-color);
  margin-left: 1rem !important;
  margin-bottom: 0;
}

.articleInner li {
  margin: 20px 0;
}

.articleInner a {
  color: var(--secondary-color);
}

.articleInner img {
  width: 95%;
  max-width: 650px;
  height: auto;
  margin: 40px 0 0 1rem;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.articleCard {
  max-width: 300px;
  flex: 1 1 calc(33.333% - 40px);
  box-sizing: border-box;
  text-align: left;
  padding: 1rem 1rem 0 1rem;
  border: solid 1px transparent;
}

.articleCard:hover{
  border: solid 1px var(--border-color);
}

.articleCardMeta{
  font-size: 0.8rem !important;
  color: var(--secondary-color) !important;
  font-weight: 400 !important;
}

.articleImage {
  width: 100%;
  height: auto;
  filter: grayscale(1) brightness(1.2) contrast(1.6);
  transition: 0.5s;
}

.articleCard:hover .articleImage {
  filter: grayscale(0);
}

.articleCard h3 {
  margin: 20px 0;
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 1.4rem;
  transition: opacity 0.5s;
}

.articleCard p {
  color: var(--text-color);
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 300;
}

.section {
  margin-bottom: 1rem;
}

.articleLink {
  display: inline-block;
  margin-top: 10px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.articleLink:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .sectionsOverview{
    display: none;
  }
}

@media (max-width: 768px) {
  .heroBlog h1, .section h1 {
    font-size: 2.5rem;
  }

  .heroBlog h1{
    margin-top: 8rem;
  }

  .articleWrapper {
    min-height: 40px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .sectionsOverview{
    display: none;
  }

  .backLink{
    display: none;
  }

  .grid {
    flex-direction: column;
    align-items: center;
  }

  .articleCard {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .heroBlog {
    min-height: 30vh;
  }

  .articleWrapper {
    min-height: 40px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .sectionsOverview{
    display: none;
  }

  .backLink{
    display: none;
  }

  .heroBlog h1, .section h1 {
    font-size: 2rem;
  }
}
