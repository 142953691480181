/* Pricing.css */
.pricing-container {
    max-width: calc(1200px - 2rem);
    margin-inline: auto;
    padding: 8rem 0rem;
    text-align: left;
    background-color: var(--secondary-color);
    animation: fadeIn 1.5s;
}

.page-title{
    font-size: 3rem;
    text-align: center;
    margin-bottom: 0;
    animation: fadeIn 1.5s;
}

.page-sub-heading{
    text-align: center;
    margin-bottom: 3rem;
    animation: fadeIn 1.5s;
}

.page-sub-heading a{
    text-decoration: underline;
}
.pricing-toggle {
    margin-bottom: 2rem;
}

.switch-payment{
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.9rem;
}

.pricing-cards {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    margin-bottom: 2rem;
}

.pricing-card {
    background-color: #f4f4f4;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 28%;
    position: relative;
}

.enterprise-card{
    width:100%;
    box-sizing: border-box;
    padding: 2rem;
    margin-inline: auto;
    background-color: var(--primary-color);
    border: solid 4px var(--primary-color);
    animation: fadeInRight 1s;
    transition: 1s;
    color: var(--text-color);
    text-align: center;
}

.enterprise-features-wrapper{
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    flex-wrap: wrap;
}

.enterprise-features-box{
    display: flex;
    flex-direction: column;
    width: calc(25% - 1.5rem);
    padding: 1rem;
    box-sizing: border-box;
    border: solid 2px var(--text-color);
    justify-content: flex-start;
    align-items: center;
    background-color: var(--text-color);
    color: var(--primary-color);
}

.enterprise-features-box img{
    width: 50%;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.enterprise-features-title{
    font-weight: 600 !important;
    text-align: center;
}

.enterprise-features-text{
    text-align: center;
}

.pricing-card h2 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.price-wrapper, .old-price-wrapper{
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    flex-direction: column;
}

.old-price-wrapper{
    justify-content: flex-end !important;
    flex-direction:row !important;
    align-items: flex-start;
    padding: 0.5rem;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    margin-top: 0 !important;
    animation: fadeIn 1s;
}

.was-text{
    font-size: 0.8rem !important;
    margin-right: 0.6rem;
    margin-top: 0.9rem !important;
}

.price-number, .old-price-number, .old-price-number-dark{
    position: relative;
    font-size: 5rem !important;
    font-weight: 600;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 1rem;
}

.old-price-number, .old-price-number-dark{
    font-size: 2.2rem !important;
    margin: 0 !important;
}

.old-price-number::before, .old-price-number-dark::before {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 48%;
}

.old-price-number-dark::before{
    border-bottom: 3px solid var(--text-color);
    -webkit-transform: skewY(-5deg);
    transform: skewY(-5deg);
}

.old-price-number::before {
    border-bottom: 3px solid var(--primary-color);
    -webkit-transform: skewY(-5deg);
    transform: skewY(-5deg);
}


.per-month{
    font-size: 1rem !important;
    text-align: left;
    margin-top: 0 !important;
}

.contract-text{
    font-size: 0.8rem !important;
    margin-top: 0 !important;
}

.pricing-card p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.divider-with{
    font-size: 1rem !important;
    margin: 2.5rem 0 0 0 !important;
}

.pricing-card ul {
    list-style-type: none;
    padding: 0;
    font-size: 0.9rem;
}

.pricing-card ul li {
    padding: 0.9rem 0;
    border-bottom: 1px solid var(--primary-color);
    line-height: 1.5;
}

.professional-card ul li{
    border-bottom: 1px solid var(--text-color);
}

/* Unique classes for individual cards */
.starter-card {
    background-color: var(--text-color);
    animation: fadeInLeft 1s;
    border: solid 4px var(--primary-color);
    transition: 1s;
}

.professional-card {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: solid 4px var(--primary-color);
    transition: 1s;
}

.premium-card {
    background-color: var(--secondary-color);
    border: solid 4px var(--primary-color);
    animation: fadeInRight 1s;
    transition: 1s;
}

.not-selected{
    opacity: 0.3;
    transform: translateY(2rem);
    transition: 1s;
}

.filters-and-switch-wrapper{
    padding: 1rem 2rem 0 2rem;
    border: solid 4px var(--primary-color);
    margin-bottom: 2rem;
    position: relative;
    position: relative;
}

.filter-container{
    margin: 2rem 0;
    display: flex;
    position: relative;
}

.calculation-information{
    font-size: 0.9rem;
    line-height: 1.5;
}

.information-symbol{
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 0rem 0.35rem;
    margin-left: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
}

.custom-tooltip {
    position: absolute;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.8rem;
    font-size: 0.8rem;
    z-index: 10;
    line-height: 1.5;
    top: 2rem;
    left: 0px;
    white-space: normal;
    word-wrap:normal;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.filter-item{
    display: flex;
    flex-direction: column;
    position: relative;
}

.filter-container input{
    margin: 0.5rem 2rem 0 0;
    border: solid 4px var(--primary-color);
    padding: 0.6rem;
    font-family: 'Inter';
}

.currency-dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 4px var(--primary-color);
    background-color: var(--text-color);
    padding: 0.3rem 0.6rem;
    font-family: 'Inter';
    font-weight: 600;
    position: absolute;
    right: 0;
    border-radius: 0; /* Remove rounded corners */
}


.multiply-symbol{
    font-size: 2rem;
    font-weight: 600;
    align-self: end;
    padding: 0.5rem 1.8rem 0.4rem 0;
}

.calc-result{
    font-size: 1.6rem;
    font-weight: 600;
    align-self: end;
    padding: 0.5rem 1.8rem 0.6rem 0;
}

/* Switch container */
.switch-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary-color);
    border: 4px solid var(--primary-color);
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: var(--primary-color);
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--text-color);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.select-button, .reset-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    padding: 0.6rem 0;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: 0.5s ease;
    border: solid 2px transparent;
}

.reset-button{
    width: unset;
    min-width: 12rem;
    top: 1rem;
    right: 2rem;
    position: absolute;
}

.select-button:hover, .reset-button:hover {
    background-color: var(--text-color);
    border: solid 2px var(--primary-color);
    color: var(--primary-color);
}

.professional-card button, .enterprise-card button {
    border: solid 2px var(--text-color);
}

@media (max-width: 1240px) {
    .filters-and-switch-wrapper{
        display: none;
    }
}

@media (max-width: 768px) {
    .pricing-cards{
        flex-direction: column;
    }
    .pricing-card {
        width: calc(90% - 8px);
        padding: 5%;
    }
    .enterprise-features-box{
        width: calc(50% - 1rem);
    }
}

@media (max-width: 480px) {
    .page-title{
        font-size: 3rem;
        text-align: center;
    }

    .filters-and-switch-wrapper{
        display: none;
    }

    .pricing-cards{
        flex-direction: column;
    }
    .pricing-card {
        width: calc(90% - 8px);
        padding: 5%;
    }

    .switch-container {
        margin-inline: auto;
        justify-content: flex-start;
    }

    .enterprise-card{
        width: calc(100%);
        box-sizing: border-box;
    }

    .enterprise-features-box{
        width: 100%;
    }
}