/* Existing styles */
.footer {
  background: var(--secondary-color);
  color: var(--primary-color);
  padding: 2rem 1rem;
  text-align: left;
  z-index: 10;
}

.footer h1 {
  font-size: 3rem;
  font-weight: 600;
  color: var(--primary-color);
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input,
.form-group textarea {
  width: calc(100% - 1rem);
  padding: 0.5rem;
  border: none;
  border-radius: 0;
  border-top: 2px solid transparent;
  border-inline: 2px solid transparent;
  border-bottom: 2px solid var(--primary-color) !important;
  background-color: transparent;
}

.form-group textarea {
  height: 10rem; /* Fixed height */
  resize: none; /* Prevent resizing */
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: var(--primary-color);
  font-family: 'Inter';
  font-size: 0.9rem;
  font-weight: 500;
}

.submit-button {
  width: 50%;
  padding: 0.75rem 1.5rem;
  border: solid 2px transparent;
  border-radius: 0;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--secondary-color);
  border: solid 2px var(--primary-color);
  color: var(--primary-color);
}

/* Styles for focused inputs and textareas */
.form-group input:focus,
.form-group textarea:focus {
  outline: none; /* Remove default browser outline */
  border: 2px solid var(--primary-color);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .footer h1 {
    font-size: 2rem;
  }

  .footer p {
    font-size: 0.9rem;
  }

  .footer-grid {
    grid-template-columns: 1fr;
  }

  .submit-button {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 480px) {
  .footer h1 {
    font-size: 1.5rem;
  }

  .footer p {
    font-size: 0.8rem;
  }

  .submit-button {
    padding: 0.5rem;
    margin-inline: auto;
    width: 100%;
  }
}
