/* Hero.css */
.hero {
  color: var(--primary-color);
  padding: 8rem 3rem 0 3rem;
  text-align: left;
  margin-left: -3rem;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-color: var(--secondary);
}

.hero-content {
  width: 100%;
  padding: 3rem 0 0 0;
  animation: zoomIn 1s;
  margin-bottom: 6rem;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-video{
  width: 30rem;
  z-index: 5;
  border: 0.5rem solid var(--border-lite);
}

.image-background {
  background-color: var(--secondary-color);
  position: relative;
}

.screenshot {
  max-width: 100%;
  margin-inline: auto;
  box-shadow: rgba(0, 0, 0, 0.5) 0px -15px 50px -12px;
  z-index: 10;
  animation: fadeInUp 1s;
}

.hero h1 {
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: var(--primary-color);
  position: relative;
}

.underline {
  font-weight: 700;
}

.hero p {
  font-size: 1rem;
  width: 60%;
  line-height: 1.4;
  margin: 2rem auto;
  letter-spacing: 1px;
  text-align: center;
}

.hero button {
  padding: 0.8rem 1.5rem;
  background: none;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: solid 2px var(--secondary-color);
  cursor: pointer;
  border-radius: 0;
  transition: 0.3s;
  margin-right: 1rem;
  font-size: 1rem;
}

.hero button:hover {
  background-color: transparent;
  color: var(--primary-color);
  border: solid 2px var(--primary-color);
  animation: tada 1s;
}

@media (max-width: 1240px) {

  .hero-content {
    width: 100%;
    padding-inline: 1rem;
    box-sizing: border-box;
    align-items: flex-start;
  }

  .hero h1 {
    width: 100%;
    text-align: left;
  }

  .hero p {
    width: 100%;
    text-align: left;
  }

  .hero button {
    margin-inline: 0rem;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .screenshot{
    display: none;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero button {
    padding: 0.75rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .hero {
    height: fit-content;
    min-height: 80dvh;
    padding-top: 7rem;
    padding-bottom: 3rem;
  }

  .hero-content {
    width: 100%;
    padding-top: 0;
  }

  .hero h1 {
    font-size: 3.4rem;
  }

  .hero p {
    font-size: 0.9rem;
  }

  .hero button {
    padding: 0.5rem 1rem;
  }
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: 1;
}

.line {
  position: absolute;
  width: 6px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.0);
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #82ca9d 75%, #82ca9d 100%);
    animation: drop 7s 3.5s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  &:nth-child(1) {
    margin-left: -25%;
    &::after {
      animation-delay: 2s;
    }
  }

  &:nth-child(3) {
    margin-left: 25%;
    &::after {
      animation-delay: 2.5s;
    }
  }
}

@keyframes drop {
  0% {
    top: 110%;
  }
  100% {
    top: -50%;
  }
}
