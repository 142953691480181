/* UseCaseSlider.css */

/* Container for the entire slider */
.ucs-slider-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
}

/* Each slide */
.ucs-slide {
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--text-color);
    padding: 1rem;
    animation: fadeInUpBig 1s;
    background-color: var(--background);
}

/* Section styles */
.ucs-section {
    margin-bottom: 3rem;
}

.ucs-section h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 500;
}

.ucs-section p,
.ucs-section li {
    font-size: 0.9rem;
    line-height: 1.6;
}

/* Use Case Title Box (Yellow Border with Filled Heading) */
.ucs-usecase-title-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 0 1rem;
}

.ucs-usecase-title-box h1 {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 2.4rem;
    padding-top: 12rem;
}

/* Yellow Box for Key Features */
.ucs-yellow-box {
    width: 100%;
    padding: 1rem;
    border: 2px solid #FFD700;
    box-sizing: border-box;
}

.ucs-yellow-box h2 {
    margin: 0 0 1rem 0;
    color: #fff;
    padding: 0.5rem 1rem;
}

/* Page Number */
.ucs-page-number {
    text-align: right;
    font-size: 0.9rem;
    color: #333;
    margin-top: 1rem;
}

/* Slider Controls */
.ucs-slider-controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0rem;
    margin-bottom: 0.5rem;
    margin-top: 8rem;
}

.ucs-slider-controls button {
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
    transition: background 0.5s;
}

.ucs-slider-controls button:hover {
    background-color: var(--border-lite);
    transition: background 0.5s;
}

.ucs-challenge-section, .ucs-how-helped-section{
    background-color: var(--secondary);
    color: var(--background);
    padding: 0.5rem 2rem 1rem 2rem;
    margin-bottom: 2rem;
    max-width: 100%;
    height: fit-content;
    box-sizing: border-box;
}

.ucs-how-helped-section{
    background-color: var(--background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

.ucs-key-features-wrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.ucs-benefit-item{
    border: 3px solid var(--border-color);
    padding: 0rem 0.8rem;
}

.ucs-feature-item img{
    width: 100%;
}

.ucs-benefit-title{
    border-bottom: 3px solid var(--border-color);
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.ucs-slider-indicators {
    display: flex;
    justify-content: center;
}

.ucs-indicator-box {
    width: 1.2rem;
    height: 1.2rem;
    background-color: var(--border-lite);
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.ucs-indicator-box.active {
    background-color: var(--secondary); /* Highlight color */
}

.ucs-challenge-and-video{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.ucs-video-section{
    max-width: 50%;
}

.ucs-video-section video{
    width: 100%;
    border: 1px solid var(--border-color);
}

.ucs-challenge-and-help-withVideo{
    width: 50%;
}


@media (max-width: 900px) {
    .ucs-key-features-wrapper{
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Responsive adjustments */
@media (max-width: 500px) {
    .ucs-slider-container {
        padding: 1rem;
    }

    .ucs-key-features-wrapper{
        grid-template-columns: repeat(1, 1fr);
    }

    .ucs-slide {
        padding: 1rem;
    }

    .ucs-slider-controls {
        justify-content: center;
    }

    .ucs-section h2 {
        font-size: 1.5rem;
    }

    .ucs-usecase-title-box h1,
    .ucs-yellow-box h2 {
        font-size: 1.5rem;
    }

    .ucs-challenge-and-video{
        flex-direction: column-reverse;
    }

    .ucs-challenge-and-help-withVideo{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ucs-challenge-section, .ucs-how-helped-section {
        width: 100%;
        margin: 0 0 2rem 0;
    }

    .ucs-video-section{
        max-width: 100%;

    }
}
