.header {
    padding: 30px 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
    font-family: 'Inter', sans-serif;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 2rem;
    margin-left: 1rem;
}

.header nav {
    display: flex;
    align-items: center;
}

.header nav a {
    margin: 0 1rem;
    color: var(--text-color);
    transition: color 0.3s;
}

.navLinkDark{
    margin: 0 1rem;
    color: var(--primary-color);
    transition: color 0.3s;
}

.navLinkDark:hover{
    color: var(--primary-color);
}

.header nav a:hover {
    color: var(--secondary-color);
}

.header button {
    padding: 0.8rem 1.5rem;
    background: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: solid 2px var(--secondary-color);
    cursor: pointer;
    border-radius: 0;
    transition: 0.3s;
    margin-right: 1rem;
    font-size: 1rem;
}

.burgerMenu {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--secondary-color);
    margin-bottom: 0.4rem;
}

@media (max-width: 768px) {
    .burger-menu {
        display: block;
    }

    .header nav {
        display: none;
        width: 100%;
        text-align: center;
    }
    .header nav.open {
        display: flex;
    }

    .header nav a {
        margin: 0.5rem 0;
    }

    .header .container {
        align-items: flex-start;
    }
}

@media (max-width: 480px) {
    .header .container {
        align-items: center;
    }

    .header nav {
        display: none;
        align-items: center;
    }

    .header nav.open {
        display: flex;
    }

    .header nav a {
        margin: 0.5rem 0;
    }
}
