.faq-container {
    margin: 0 auto;
    padding: 2rem;
    padding: 2rem 1rem;
  }
  
  .faq-title {
    font-size: 3rem;
    font-weight: 600;
    color: var(--secondary-color);
  }
  
  .faq-item {
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--primary-color);
  }
  
  .faq-question {
    width: 100%;
    padding: 1rem;
    background: var(--primary-color);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 1.2rem;
    transition: border 0.3s ease;
    border: solid 1px transparent;
  }
  
  .faq-question:hover {
    border: solid 1px var(--text-color);
  }
  
  .faq-answer {
    overflow: hidden;
    transition: max-height 0.3s ease;
    line-height: 1.5;
    background-color: #f9f9f9;
  }
  
  .faq-answer p {
    padding: 1rem;
    background-color: #f9f9f9;
    margin: 0;
  }
  