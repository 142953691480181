.global-goals-logo{
    width: 30rem;
    margin-inline: auto;
}

@media (max-width: 480px) {

    .global-goals-logo{
        width: 90%;
    }
}