.company-info-container {
    max-width: calc(1000px - 2rem);
    margin-inline: auto;
    text-align: left;
    padding-top: 2rem;
    background-color: var(--primary-color);
    animation: fadeIn 1.5s;
    color: var(--text-color);
    display: flex;
}

.page-sub-title{
    font-size: 2.4rem;
    font-weight: 600;
}

.company-page-main-text-wrapper{
    margin-top: 8rem;
}

.company-page-main-text{
    color: var(--text-color);
    max-width: 50rem;
    margin-inline: auto;
    margin-bottom: 2rem;
}

.company-info-text{
    padding: 8rem 0rem;
    width: 50%;
}

.company-graphic{
    padding-top: 10.3rem;
    width: 42%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.company-graphic-box-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-graphic-box{
    height: 2rem;
    width: 2rem;
    border: solid 4px var(--secondary-color);
    background-color: var(--primary-color);
    z-index: 2;
}

.company-graphic-box-dash-dark{
    height: 4px;
    width: 8rem;
    background-color: transparent;
}
.company-graphic-box-dash-light{
    height: 4px;
    width: 8rem;
    background-color: var(--secondary-color);
}

.company-graphic-line{
    height: 39.3rem;
    width: 4px;
    background-color: var(--primary-color);
    margin-inline: auto;
    position: relative;
    z-index: 1;
}

.company-graphic-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, 
                                transparent 30%, 
                                var(--secondary-color) 45%, 
                                var(--secondary-color) 55%, 
                                transparent 55%);
    animation: moveGlow 8s ease-in infinite; /* Speed and type of animation */
}

@keyframes moveGlow {
    0% {
        top: -50%; /* Start off the top of the line */
        transform: scaleY(0.2);
    }

    50% {
        top: 80%; /* Move through to the bottom */
        transform: scaleY(1);
    }

    100% {
        top: 80%; /* Move through to the bottom */
    }
}


.company-page-sub-text{
    text-align: left;
    margin-bottom: 3rem;
    margin-inline: auto;
}


@media (max-width: 768px) {
    .company-graphic{
        display: none;
    }

    .company-info-text{
        width: 90%;
        margin-inline: auto;
    }
}