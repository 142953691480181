/* Default Styles */

.cardWithImageAlternate-container {
    display: flex;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    width: calc(100% - 4rem);
    height: auto;
    text-align: left;
    padding: 2rem;
    margin-top: 4.5rem;
    z-index: 1;
}

/* Light Variant */
.cardWithImageAlternate-container-light {
    display: flex;
    justify-content: center;
    gap: 4rem;
    width: fit-content;
    height: auto;
    text-align: left;
    padding: 0rem;
    margin-top: 4.5rem;
    z-index: 1;
    /* Add any light-specific overrides here */
}

.card-left{
    background: linear-gradient(to bottom left, #ffffff10 0%, transparent 50%);
}

/* Light Variant */
.card-left-light{
    background: linear-gradient(to bottom left, #181E2110 0%, transparent 50%);
    /* Add any light-specific overrides here */
}

.card-right{
    background: linear-gradient(to bottom right, #ffffff10 0%, transparent 50%);
}

/* Light Variant */
.card-right-light{
    background: linear-gradient(to bottom right, #181E2110 0%, transparent 50%);
    /* Add any light-specific overrides here */
}

.cardWithImageAlternate-placeholder {
    width: 20%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
    background-color: var(--primary-color);
}

.cardWithImageAlternate-placeholder:hover {
    transform: perspective(3000px) rotateY(5deg);
}

/* Light Variant */
.cardWithImageAlternate-placeholder-light {
    width: 20%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
    transition: transform 0.5s ease 0s;
}

/* Light Variant Hover */
.cardWithImageAlternate-placeholder-light:hover {
    transform: perspective(3000px) rotateY(5deg);
}

.cardWithImageAlternate-placeholder-right {
    width: 50%;
    height: auto;
    margin: auto;
    transition: transform 0.3s;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
    transition: transform 0.5s ease 0s;
    background-color: var(--primary-color);
}

.cardWithImageAlternate-placeholder-right:hover {
    transform: perspective(3000px) rotateY(5deg);
}

/* Light Variant */
.cardWithImageAlternate-placeholder-right-light {
    width: 50%;
    height: auto;
    margin: auto;
    transition: transform 0.3s;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
    transition: transform 0.5s ease 0s;
}

/* Light Variant Hover */
.cardWithImageAlternate-placeholder-right-light:hover {
    transform: perspective(3000px) rotateY(5deg);
}

.cardWithImageAlternate-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Light Variant */
.cardWithImageAlternate-content-light {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Add any light-specific overrides here */
}

/* Classes to swap the order */
.order-right {
    order: 2;
}

/* Light Variant */
.order-right-light {
    order: 2;
}

.order-left {
    order: 1;
}

/* Light Variant */
.order-left-light {
    order: 1;
}

.cardWithImageAlternate-subtitle {
    margin-bottom: 0.5rem !important;
    border: solid 1px var(--text-color);
    padding: 0.5rem 0.8rem;
    position: relative;
    font-size: 0.7rem;
    border-radius: 1rem;
    width: fit-content;
    text-transform: uppercase;
}

/* Light Variant */
.cardWithImageAlternate-subtitle-light {
    margin-bottom: 0.5rem !important;
    border: solid 1px var(--background);
    color: var(--secondary-color) !important;
    background-color: var(--background);
    padding: 0.5rem 0.8rem;
    position: relative;
    font-size: 0.7rem;
    border-radius: 1rem;
    width: fit-content;
    text-transform: uppercase;
    /* Add light-specific style overrides here, if any */
}

.cardWithImageAlternate-title {
    font-size: 2.2rem;
    line-height: 1.1;
    font-weight: 900 !important;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: var(--secondary-color);
    text-transform: uppercase;
}

/* Light Variant */
.cardWithImageAlternate-title-light {
    font-size: 2.2rem;
    line-height: 1.1;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: var(--background);
    max-width: 30rem;
    /* Add light-specific style overrides here, if any */
}

.cardWithImageAlternate-body p {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0 0 1rem 0;
    letter-spacing: 1px;
    color: var(--text-color);
}

/* Light Variant */
/* If necessary, you can define .cardWithImageAlternate-body-light p or similar rules here */

.cardWithImageAlternate-body-light p {
    font-size: 0.9rem;
    max-width: 30rem;
    line-height: 1.4;
    margin: 0 0 1rem 0;
    letter-spacing: 1px;
    color: var(--background);
    /* Add light-specific style overrides here, if any */
}

/* Responsive layout adjustments */
@media (max-width: 1024px) {
    .cardWithImageAlternate-container,
    .cardWithImageAlternate-container-light {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .cardWithImageAlternate-container,
    .cardWithImageAlternate-container-light {
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5rem;
    }
}

@media (max-width: 480px) {
    .cardWithImageAlternate-container,
    .cardWithImageAlternate-container-light {
        flex-direction: column;
        width: 90%;
        padding: 0;
        margin-inline: auto;
    }

    .card-left,
    .card-left-light {
        background: none;
    }

    .card-right,
    .card-right-light {
        background: none;
    }

    .cardWithImageAlternate-subtitle,
    .cardWithImageAlternate-subtitle-light {
        padding: 0.4rem 0.6rem;
        font-size: 0.6rem !important;
    }

    /* Force image to always be first on mobile */
    .cardWithImageAlternate-placeholder,
    .cardWithImageAlternate-placeholder-light {
        order: 1 !important;
        width: 100%;
    }

    .cardWithImageAlternate-content,
    .cardWithImageAlternate-content-light {
        order: 2 !important;
    }
}
