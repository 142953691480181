@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  color: var(--primary-color);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes barGrow {
  0% {
      transform: scaleY(0);
  }
  5%{
    transform: scaleY(1.2);
}
  8%{
      transform: scaleY(1);
  }
  60%{
    transform: scaleY(1);
  }
  62%{
    transform: scaleY(1.2);
  }

  65% {
      transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
}
}
html {
  scroll-behavior: smooth;
}