/* Styles for the Contact Section */
.contact-section {
    padding: 8rem 0rem;
    text-align: left;
    background-color: var(--secondary-color);
  }
  
  .contact-section h1 {
    font-size: 3rem;
    font-weight: 600;
    color: var(--primary-color);
    animation: fadeIn 1.5s;
  }
  
  .contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto;
    animation: fadeIn 1.5s;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    animation: fadeInRight 1s;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: calc(100% - 1rem);
    padding: 1rem;
    border: none;
    border-radius: 0;
    border: 4px solid var(--primary-color) !important;
    background-color: var(--text-color);
  }
  
  .form-group textarea {
    height: 11.6rem; /* Fixed height */
    resize: none; /* Prevent resizing */
  }
  
  .form-group input::placeholder,
  .form-group textarea::placeholder {
    color: var(--primary-color);
    font-family: 'Inter';
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .submit-button {
    width: 50%;
    padding: 0.75rem 1.5rem;
    border: solid 4px transparent;
    border-radius: 0;
    background-color: var(--primary-color);
    color: var(--text-color);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: var(--text-color);
    border: solid 4px var(--primary-color);
    color: var(--primary-color);
  }
  
  /* Styles for focused inputs and textareas */
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none; /* Remove default browser outline */
    border: 4px solid var(--primary-color);
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .contact-section h1 {
      font-size: 2rem;
    }
  
    .contact-section p {
      font-size: 0.9rem;
    }
  
    .contact-grid {
      grid-template-columns: 1fr;
    }
  
    .submit-button {
      padding: 0.5rem 1rem;
    }
  }
  
  @media (max-width: 480px) {

    .form-group input,
    .form-group textarea {
      width: calc(88vw - 2rem);
    }

    .map-container{
      width: 88vw;
    }
    .contact-grid{
      width: 90vw;
    }
  
    .contact-section p {
      font-size: 0.8rem;
    }
  
    .submit-button {
      padding: 0.5rem;
      margin-inline: auto;
      width: 90vw;
    }
  }
  
  /* Styles for the map container */

  .map-container iframe {
    border: none !important; /* Remove any border */
    outline: none !important; /* Remove any outline */
    box-shadow: none !important; /* Remove any shadow that may be applied */
  }
  
  .map-container{
    appearance: none;
    outline: none;
    border:  none;
    border: solid 4px var(--primary-color);
    animation: fadeInLeft 1s;
  }
  
  .map-container p {
    margin-top: 1rem;
    font-size: 1rem;
    color: var(--primary-color);
  }
  