footer{
  font-size: 0.9rem;
}

.footer-column {
  padding: 0rem 2rem 2rem 1rem;
}

footer .logo {
  height: 1.9rem;
}

.footer-column h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 0rem;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 0.5rem;
}

.footer-column ul li a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: var(--secondary-color);
}

.footer .social-icons a {
  margin-right: 0.5rem;
  text-decoration: none;
  color: var(--primary-color);
}

.footer .social-icons a:hover {
  color: #007bff;
}

.social-icon{
  width: 1.6rem;
  
}

.copyright{
  margin-left: 1rem;
}

@media (max-width: 480px) {
  .footer-grid{
    grid-template-columns: 1fr !important;
  }
}