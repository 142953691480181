.download-datasheet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(var(--background), var(--border-lite));
}


.download-datasheet-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.download-datasheet-thumbnail-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.download-datasheet-thumbnail{
    max-width: 40rem;
    width: 90%;
    animation: bounceInUp 1s;
    margin-top: 5rem;
    border-bottom: none;
}

.download-datasheet-heading{
    color: var(--text-color);
    font-size: 2.4rem;
    font-weight: 600;
    margin-top: 5rem;
    text-align: center;
}

.download-datasheet-container {
    background-color: #6c67d3;
    color: var(--text-color);
    width: fit-content;
    position: relative; /* Set relative positioning */
    display: flex;
    align-items: center;
    animation: bounceInUp 1s;
    z-index: 10; /* Ensure this is higher than the :before element */
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
    cursor: pointer;
    padding: 1rem;
}

.download-data-sheet-title {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
}

.download-data-button {
    width: 2rem;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.download-icon {
    width: 1rem;
}

@media (max-width: 768px) {
    .download-datasheet-heading {
        font-size: 1.8rem;
        max-width: 90%;
    }
}