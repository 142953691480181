/* Existing header styles... */

header {
    padding: 30px 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
    font-family: 'Inter', sans-serif;
}

header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn 1s;
}

header .logo {
    height: 1.9rem;
    margin-left: 1rem;
}

header nav {
    display: flex;
    align-items: center;
}

header nav a {
    margin: 0 1rem;
    color: var(--text-color);
    transition: color 0.3s;
    font-size: 0.9rem;
    z-index: 3;
}

header nav a:hover {
    color: var(--text-color);
}

header button {
    padding: 0.6rem 1rem;
    background: none;
    background-color: var(--text-color);
    color: var(--primary-color);
    border: solid 2px var(--primary-color);
    cursor: pointer;
    border-radius: 0;
    transition: 0.3s;
    margin-right: 1rem;
    font-size: 0.9rem;
}

.login-button:hover {
    background-color: transparent;
    color: var(--text-color);
    border: solid 2px var(--text-color);
}

.burger-menu {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--text-color);
    margin-bottom: 0.4rem;
    margin-right: 0.5rem;
}

/* Dropdown styles */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 0.9rem;
    margin: 0 0rem;
    z-index: 3;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--primary-color);
    min-width: 120px;
    z-index: 1;
    padding-top: 3rem;
    margin-top: -2.5rem;
    margin-left: 0rem;
    border-left: 1px solid var(--text-color);
    z-index: 1;
}

.dropdown-content a {
    color: var(--text-color);
    padding: 1rem 16px;
    margin-inline: 0;
    text-decoration: none;
    display: block;
    font-size: 0.9rem;
}

.dropdown-content a:hover {
    background-color: var(--text-color);
    color: var(--primary-color);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .burger-menu {
        display: block;
    }

    header nav {
        display: none;
        width: 100%;
        text-align: center;
    }

    header nav.open {
        display: flex;
    }

    header nav a, .dropbtn {
        margin: 0.5rem 0;
    }

    header .container {
        align-items: flex-start;
    }
}

@media (max-width: 480px) {
    header .container {
        align-items: center;
    }

    header nav {
        display: none;
        align-items: center;
    }

    header nav.open {
        display: flex;
    }

    header nav a, .dropbtn {
        margin: 0.5rem 0;
    }

    header button {
        margin-right: 0;
    }
}

/* Dark and light mode styles */
header.dark-mode {
    --primary-color: #181e21;
    --text-color: #ffffff;
}

header.light-mode {
    --primary-color: #efbd33;
    --text-color: #181e21;
}
