/* MobileNav.css */
.mobile-nav {
    display: none;
    position: fixed;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-color);
    left: 0;
    top:5rem;
    width: 100%;
    height: 100vh;
    padding: 1rem 0rem;
    padding-top: 2rem;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .mobile-nav a {
    margin: 0;
    color: #1f2a33;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 3rem;
  }

  .burger-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1f2a33;
  }

  .logo-mobile-menu{
    height: 2rem;
    justify-self: flex-end;
  }

  .burger-menu-mobile {
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--primary-color);
    margin-right: 2rem;
}
  .mobile-nav a:hover {
    color: var(--secondary-color);
  }
  
@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}
  @media (max-width: 768px) {
    .mobile-nav {
      display: flex;
    }
  }
  
