.features {
  padding: 4rem 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
}

.header-wrapper{
  background-color: var(--secondary-color);
  position: relative;
  left: calc(-50vw + 50% - 0.5rem);
  width: 100vw;
  height: 20rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.features h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 6rem;
  color: var(--secondary-color);
  z-index: 10;
  animation: flipInX 1s;
  width: fit-content;
  margin-inline: auto;
  padding: 1rem 1.5rem;
}

.features h4 {
  line-height: 1.4;
  margin: 0 0 2rem 0;
  margin-inline: auto !important;
  width: 40rem;
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: var(--text-color);
  animation: slideInUp 1s;
  text-align: left;
  padding: 1rem 2rem;
  font-weight: 400;
}

.features p {
  line-height: 1.4;
  margin: 2rem 0;
  letter-spacing: 1px;
  color: var(--text-color);
  animation: slideInUp 1s;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .cardGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features h1 {
    font-size: 2.4rem;
  }

  .features h2 {
    font-size: 2rem;
  }

  .features h4 {
    margin: 0 0 2rem 0.5rem;
    width: 85%;
  }

  .features p {
    font-size: 0.9rem;
  }

  .cardGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .features h1 {
    font-size: 2rem;
  }

  .features h2 {
    font-size: 1.4rem;
  }

  .features h4 {
    margin: 0 0 2rem 0.5rem;
    width: 85%;
  }

  .features p {
    font-size: 0.8rem;
  }

  .cardGrid {
    grid-template-columns: 1fr;
  }

  .screenshot {
    display: none;
  }
}

/* Initially hidden */
.hidden {
  opacity: 0;
}

/* When the element is in view (middle of the viewport) */
.in-view {
  opacity: 1;
  animation: fadeInUp 1s;
}
