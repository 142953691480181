/* App.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--primary-color);
}

:root {
  --primary-color: #181e21;
  --secondary-color: #efbd33;
  --background-color: #1f2a33;
  --text-color: #ffffff;
  --border-color: #808284;
  --border-lite: #2e3840;
  --red: #f35431;
  --orange: #ff7300;
  --green: #82ca9d;
  --blue: #8884d8;
  --primary: #ffffff;
  --secondary: #efbd33;
  --background: #181e21;
  --dashboard: #1f282f;
  --backgroundMid: #262f33;
  --card-background: #1f2a33;
}


a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

.light-wrapper{
  width: 100%;
  background-color: var(--secondary-color);
}

h1 {
  letter-spacing: -1px;
}
